// NPM
import React from 'react'

// Shared components
import Description from 'src/components/Shared/Description'

const OngoingApplications = ({ section }) => {

  if(!section) {
    return null
  }

  return (
    <div className="OngoingApplications">
      <h4 className="OngoingApplications__title small-header">
        { section.title }
      </h4>

      <Description
        className="OngoingApplications__description"
        description={ section.description }
      />
    </div>
  )

}

export default OngoingApplications