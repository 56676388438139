// NPM
import React from 'react'

// Shared Components
import CommonHero from 'src/components/Shared/CommonHero'
import InfoImage from 'src/components/Shared/InfoImage'

// Components
import CareersList from './CareersList'
import OngoingApplications from './OngoingApplications'

const Careers = ({ sections, jobPostings }) => {
  return (
    <div className="Careers">
      <CommonHero section={ sections.find(s => s.uuid === "careers_hero") } />
      
      <CareersList
        sections={ sections }
        jobPostings={ jobPostings }
      />

      <div className="InfoImage__items">
        <InfoImage section={ sections.find(s => s.uuid === "careers_01") } />
        <InfoImage section={ sections.find(s => s.uuid === "careers_02") } />
        <InfoImage section={ sections.find(s => s.uuid === "careers_03") } />
      </div>

      <OngoingApplications section={ sections.find(s => s.uuid === "openApplications") } />
    </div>
  )
}

export default Careers