// NPM
import React from 'react'
import { Link } from 'gatsby'

const CareersList = ({ sections, jobPostings }) => {

  const carrerListSection = sections.find(s => s.uuid === "careersList")
  const emptyListText = (carrerListSection.miscText.find(mt => mt.uuid === "emptyListText") || {}).text
  const listItemLinkText = (carrerListSection.miscText.find(mt => mt.uuid === "listItemButtonText") || {}).text

  const renderCareerListItem = (jobPosting, jobPostingIndex) => {
    return (
      <li 
        className="CareerListItem"
        key={ `CareerListItem-${ jobPostingIndex }` }>
          <div className="CareerListItem__top">
            <span className="CareerListItem__title">
              { jobPosting.title }
            </span>
            <Link 
              to={ `./${ jobPosting.id }` }
              className="CareerListItem__link-desktop black-button">
                <span>{ listItemLinkText }</span>
                <span className="icon">
                  <img src={ require("src/images/icons/arrow-right-white.png").default } />
                </span>
            </Link>
          </div>
          <p className="CareersList__shortDescription">
            { jobPosting.shortDescription }
          </p>

          <Link 
            to={ `./${ jobPosting.id }` }
            className="CareerListItem__link-touch black-button">
              <span>Link</span>
              <span className="icon">
                <img src={ require("src/images/icons/arrow-right-white.png").default } />
              </span>
          </Link>
      </li>
    )
  }

  return (
    <div id="CareersList" className="CareersList">
      {
        carrerListSection &&
          <h3 className="small-header">
            { carrerListSection.title }
          </h3>
      }

      {
        jobPostings.length === 0 &&
          <div className="CareersList__emtpy">
            { emptyListText }
          </div>
      }

      {
        jobPostings.length > 0 &&
          <ul className="CareersList__items">
            { jobPostings.map(renderCareerListItem) }
          </ul>
      }
    </div>
  )

}

export default CareersList