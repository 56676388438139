// NPM
import React from 'react'
import _ from 'lodash'

// Shared components
import Description from 'src/components/Shared/Description'
import CommonLink from 'src/components/Shared/CommonLink'

const InfoImage = ({ section }) => {
  if(!section) {
    return null
  }

  const image = _.get(section, `media.0.image`)

  return (
    <div className="InfoImage">
      <div className="InfoImage__content">
        <Description
          description={ section.description }
          className="InfoImage__description"
        />
        <CommonLink
          link={ section.link }
          className="InfoImage__link black-button"
        />
      </div>

      <div className="InfoImage__image-holder">
        { 
          image && 
            <img 
              className="InfoImage__image"
              src={ image.url } 
              alt={ image.imageAlt }
            />
        }
      </div>
    </div>
  )

}

export default InfoImage